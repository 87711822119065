import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import SideHealth from './SideHealth';

const HealthAndWellnesClub= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main >
    
      
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Form />
            </Grid>
            <Grid item xs={12} md={4}>
           
                <Box marginBottom={4}>
                  <SideHealth />
                </Box>
             
              {/* <SidebarNewsletter /> */}
            </Grid>
          </Grid>
        </Container>
  
      </Main>
  );
};

export default HealthAndWellnesClub;
