/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Form = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h5' align='center'>
        UNIQUE CHALLENGES IN ADOLESCENCE
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        <br/>Department of Science conducted a workshop on 'Unique Challenges in Adolescence' in collaboration with People Tree Hospital, Yeshwanthpur and Maarga Foundation on Friday, 26 August 2022. This workshop enabled our students to recognize various challenges like bullying, impact of social media on adolescent minds, impact of Covid lockdown and identity formation and how to handle it.

        <br/>The students quenched their curiosity on the above stated issues by asking pertinent questions and the guest speakers provided precise and comprehensive answers to the same. The students came out of the workshop with better clarity and skills to handle the various challenges of adolescence.
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={'https://www.npsypr.com/images/events/2022_23/healthwellness/adolescence.png'}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
        </Box>
        <Typography variant='h5' align='center'>
        ADOLESCENCE IS A NEW BIRTH, FOR THE HIGHER AND MORE COMPLETELY HUMAN TRAITS ARE NOW BORN
        </Typography>
    
      </Box>
    </Box>
  );
};

export default Form;
